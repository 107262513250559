import { useReducer, useCallback, memo } from 'react';
import ColorContext from './color-context';

const defaultColorState = {
  color: null,
  set: () => {},
};

const userReducer = (state, action) => {
  if (action.type === 'SET_COLOR') {
    return {
      ...state,
      color: action.payload.color,
    };
  }

  return defaultColorState;
};

const ColorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, defaultColorState);

  const setColor = useCallback(
    (color) => {
      dispatch({
        type: 'SET_COLOR',
        payload: { color },
      });
    },
    [dispatch]
  );

  const colorContext = {
    color: state.color,
    set: setColor,
  };

  return (
    <ColorContext.Provider value={colorContext}>
      {children}
    </ColorContext.Provider>
  );
};

export default memo(ColorProvider);
