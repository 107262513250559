import './index.scss';
import { memo, useCallback, useContext, useState } from 'react';
import { errToString, toPriceWithCurrency } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import api from '../../../../../utils/api';
import sound from '../../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import Warning from '../../../../../components/warning';
import AuthContext from '../../../../../context/auth/auth-context';
import moment from 'moment/moment';
// import {IoIosRemove} from "react-icons/io";

function OrdersListProduct({
  type,
  product,
  index,
  tableId,
  onRefresh,
  setInvoices,
  setSelectedInvoice,
}) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState(false);

  const onRemoveProduct = useCallback(
    (name, id) => {
      api
        .delete(`/invoices/tables/${tableId}/order-products/${id}`)
        .then(({ data }) => {
          onRefresh();
          const audio = new Audio(sound);
          const info = `${name} ${t('notifications.removeProduct')}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          setSelectedInvoice(data);
          return setInvoices([data]);
        })
        .catch((err) => {
          console.log(t(`errors.${errToString(err)}`));
          const audio = new Audio(sound);
          const info = `${errToString(err)}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          console.log(errToString(err));
        });
    },
    [t, onRefresh, tableId, setInvoices, setSelectedInvoice]
  );

  return (
    <li className='orders_list_product' key={index}>
      <div className='orders_list_product_name'>
        <span>
          {' '}
          {`${product.name} - ${moment(product.createdAt).format('hh:mm')}`}
        </span>
        <p className='orders_list_product_name_qty'>x{product.quantity}</p>
        <p className='orders_list_product_name_price'>
          {toPriceWithCurrency(
            Number(
              product.options.reduce((acc, option) => option.price + acc, 0) +
                product.price
            ),
            user.restaurant.restaurantInfo.currency
          )}
        </p>
      </div>
      {!!product?.options?.length && (
        <div className='orders_list_product_options'>
          <span>{t('general.extras')}:</span>
          {product?.options.map((option, index) => (
            <p key={index}>{`${option.name}`}</p>
          ))}
        </div>
      )}
      {product?.notes && (
        <div className='orders_list_product_options'>
          <p>
            <span>{t('general.notes')}:</span>
            {product.notes}
          </p>
        </div>
      )}
      {showWarning && type === 'live' && (
        <Warning
          description={`${t('components.warning.mainTxt')} ${t('components.warning.delete')} ${product.name}`}
          onCancel={() => setShowWarning(false)}
          onSubmit={() => {
            setShowWarning(false);
            return onRemoveProduct(product.name, product.orderProductId);
          }}
        />
      )}
    </li>
  );
}

export default memo(OrdersListProduct);
