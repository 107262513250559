import React, { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TerminalContext from '../../../../context/terminal/terminal-context';
import { toPriceWithCurrency } from '../../../../utils';
import AuthContext from '../../../../context/auth/auth-context';

function TerminalHeader({
  selectedTable,
  onClear,
  setSelectedTable,
  setIsTerminalOpen,
  windowWidth,
  setShowBasket,
  onRefresh,
}) {
  const { products: basketProducts } = useContext(TerminalContext);
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  const basketInfo = useMemo(() => {
    const totalQty = basketProducts.reduce(
      (acc, product) => product.qty + acc,
      0
    );
    const totalPrice = basketProducts.reduce((acc, product) => {
      const optionsTotalPrice = product.options.reduce(
        (optAcc, option) => optAcc + option.price,
        0
      );
      const productTotalPrice =
        product.qty * (optionsTotalPrice + product.price);
      return acc + productTotalPrice;
    }, 0);
    return totalQty > 0 ? `${totalPrice}` : '';
  }, [basketProducts]);

  return (
    <>
      {selectedTable && (
        <div className='terminal_in_header'>
          <div
            className='page_in_header_btn'
            style={{ marginLeft: 0 }}
            onClick={() => {
              onClear();
              setSelectedTable(null);
              setIsTerminalOpen(false);
              onRefresh();
            }}
          >
            {t('general.back')}
          </div>
          {windowWidth >= 600 && (
            <h2>{`${t('dashboard.terminal.createOrder')} ${selectedTable.name}`}</h2>
          )}
          {windowWidth < 600 && <h2>{`${selectedTable.name}`}</h2>}
          {windowWidth >= 500 && windowWidth < 1190 && (
            <div
              className='terminal_basket_btn'
              onClick={() => setShowBasket(true)}
            >
              {t('shop.basket')} -{' '}
              {toPriceWithCurrency(
                Number(basketInfo),
                user.restaurant.restaurantInfo.currency
              )}
            </div>
          )}
          {windowWidth < 500 && (
            <div
              className='terminal_basket_btn'
              onClick={() => setShowBasket(true)}
            >
              {toPriceWithCurrency(
                Number(basketInfo),
                user.restaurant.restaurantInfo.currency
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default memo(TerminalHeader);
