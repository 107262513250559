import './index.scss';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import api from '../../../utils/api';
import cn from 'classnames';
import TerminalContext from '../../../context/terminal/terminal-context';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../utils';
import TerminalMenu from './terminal-menu';
import TerminalTable from './terminal-table';
import TerminalHeader from './terminal-header ';
import { useOutletContext } from 'react-router-dom';
import Loading from '../../../components/loading';

function Terminal({
  lngId,
  setIsTerminalOpen,
  onRefresh,
  selectedBranch,
  branch,
}) {
  const { t } = useTranslation();
  const { onClear, products: basketProducts } = useContext(TerminalContext);
  const { invoices } = useOutletContext();
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const [tablesLoading, setTablesLoading] = useState(true);
  const [showProductDetailsPage, setShowProductDetailsPage] = useState(false);
  const [checkForPos, setCheckForPos] = useState(false);

  const [tables, setTables] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [categories, setCategories] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [categoryProducts, setCategoryProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [pending, setPending] = useState(false);

  const onCreateOrder = useCallback(
    (e) => {
      e.preventDefault();
      setPending(true);
      const data = {
        products: basketProducts.map((product) => ({
          id: product.id,
          options: product.options.map(({ id }) => id),
          qty: product.qty,
          notes: product.note,
        })),
      };

      if (!data.products.length) {
        const audio = new Audio(sound);
        const info = 'There are no products selected for order';
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
        return setPending(false);
      }

      api
        .post(`/invoices/tables/${selectedTable.id}/orders`, data)
        .then(({ data }) => {
          setPending(false);
          const audio = new Audio(sound);
          const info = `${selectedTable.name} ${t('notifications.createOrder')}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();

          invoices.setInvoices(
            invoices.invoices.map((_invoice) => {
              if (_invoice.id === data.id) {
                return data;
              }

              return _invoice;
            })
          );
          onClear();
          setSelectedTable(null);
          setIsTerminalOpen(false);
          onRefresh();
        })
        .catch((err) => {
          const audio = new Audio(sound);
          const info = `${errToString(err)}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          console.log(errToString(err));
          setPending(false);
        });
    },
    [
      onRefresh,
      setIsTerminalOpen,
      t,
      invoices,
      basketProducts,
      onClear,
      selectedTable,
    ]
  );

  useEffect(() => {
    api
      .get(`/product-categories?languageId=${lngId}&branchId=${branch.id}`)
      .then((res) => {
        setCategoriesLoading(false);

        if (!!res.data.length) {
          setCategories(res.data);

          if (!selectedCategoryId) {
            setSelectedCategoryId(res.data[0]?.id);
          }
          if (selectedCategoryId) {
            setProductsLoading(true);

            api
              // .get(`/products?categoryId=${selectedCategoryId}&languageId=${lngId}`)
              .get(
                `/products?categoryId=${selectedCategoryId}&languageId=${lngId}&offset=0&limit=10000`
              )
              .then((res) => {
                setCategoryProducts(res.data.results);
                setProductsLoading(false);
              })
              .catch((err) => {
                const audio = new Audio(sound);
                const info = `${errToString(err)}`;
                toast.info(info, {
                  position: 'top-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                  type: 'error',
                });
                audio.play();
                console.log(errToString(err));
              });
          }
        }

        return null;
      })
      .catch((err) => {
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [t, selectedCategoryId, lngId, branch]);
  useEffect(() => {
    if (!selectedBranch) {
      return;
    }

    api
      .get(`/tables?offset=0&limit=1000&branchId=${selectedBranch.id}`)
      .then((res) => {
        setTablesLoading(false);
        return setTables(
          res.data.results.filter(({ deletedAt }) => !deletedAt)
        );
      })
      .catch((err) => {
        setTablesLoading(false);
        console.log(t(`errors.${errToString(err)}`));
      });
  }, [t, selectedBranch]);

  return (
    <>
      <div className='terminal'>
        <div
          className={cn('terminal_in', {
            'terminal_in--orders': selectedTable,
          })}
        >
          {checkForPos && (
            <TerminalHeader
              selectedTable={selectedTable}
              onClear={onClear}
              setSelectedTable={setSelectedTable}
              setIsTerminalOpen={setIsTerminalOpen}
            />
          )}
          {!selectedTable && (
            <TerminalTable
              invoices={invoices.invoices}
              tablesLoading={tablesLoading}
              tables={tables}
              setSelectedTable={setSelectedTable}
              setTablesLoading={setTablesLoading}
              setTables={setTables}
              selectedBranch={selectedBranch}
              setCheckForPos={setCheckForPos}
              checkForPos={checkForPos}
            />
          )}
        </div>
      </div>
      {selectedTable && !checkForPos && (
        <div className='loading_fullscreen'>
          <Loading />
        </div>
      )}
      {selectedTable &&
        checkForPos &&
        createPortal(
          <TerminalMenu
            checkForPos={checkForPos}
            selectedProduct={selectedProduct}
            showProductDetailsPage={showProductDetailsPage}
            onCreateOrder={onCreateOrder}
            setSelectedProduct={setSelectedProduct}
            setShowProductDetailsPage={setShowProductDetailsPage}
            categoriesLoading={categoriesLoading}
            categories={categories}
            productsLoading={productsLoading}
            categoryProducts={categoryProducts}
            setSelectedCategoryId={setSelectedCategoryId}
            selectedCategoryId={selectedCategoryId}
            pending={pending}
            onClear={onClear}
            selectedTable={selectedTable}
            setSelectedTable={setSelectedTable}
            setIsTerminalOpen={setIsTerminalOpen}
            onRefresh={onRefresh}
          />,
          document.getElementById('modal')
        )}
    </>
  );
}

export default memo(Terminal);
