import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../../utils/api';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';

function EditStaff({ setStaff, setStaffIsEditing, staff, selectedStaff }) {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
    setError(null);
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      const data = {
          staffId: selectedStaff.id,
          password
        }

      api
        .post(`/companies/staff/changePassword`, data)
        .then((res) => {
          setStaffIsEditing(false);
          setError(null);

          const audio = new Audio(sound);
          const info = `Password was updated`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();

          return setStaff(
            staff.map((_staff) => {
              if (selectedStaff.id === _staff.id) {
                return res.data;
              }
              return _staff;
            })
          );
        })
        .catch((err) => setError(t(`errors.${errToString(err)}`)));
    },
    [t, password, setStaff, staff, selectedStaff, setStaffIsEditing]
  );

  return (
    <form className='form' onSubmit={onSubmit}>
        <div className='form_columns'>
            <div className='form_column'>
                <label htmlFor=''>{t('general.password')}</label>
                <input
                    type='text'
                    value={password}
                    placeholder={t('general.password')}
                    onChange={(e) => onChangeField(e, setPassword)}
                />
            </div>
            <div className='form_column'/>
        </div>
        <div className='form_error form_error--left'>
            {error && (
                <div className='form_error_name'>
                    <PiWarningOctagonDuotone/>
                    <p>{error}</p>
                </div>
            )}
        </div>
        <div className='form_actions'>
            <button className='form_actions_btn' type={'submit'}>
          {t('general.save')}
        </button>
      </div>
    </form>
  );
}

EditStaff.propTypes = {
  staff: PropTypes.array.isRequired,
  selectedStaff: PropTypes.object.isRequired,
  setStaff: PropTypes.func.isRequired,
  setStaffIsEditing: PropTypes.func.isRequired,
};

export default memo(EditStaff);
