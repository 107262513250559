import './index.scss';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import LogoNew from '../../assets/logo/QRrobo version 1 - black.png';
import api from '../../utils/api';
import AuthContext from '../../context/auth/auth-context';
import SignUp from './sign-up';
import SignIn from './sign-in';
import ForgetPassword from './forget-password';
import { errToString } from '../../utils';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import Loading from '../../components/loading';

function Auth({ type = 'sign-in', lng }) {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [authMode, setAuthMode] = useState(
    type === 'sign-up' ? 'sign-up' : 'sign-in'
  );
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsIsChecked, setTermsIsChecked] = useState(false);
  const [error, setError] = useState(null);
  const [isVerificationFormOpen, setIsVerificationFormOpen] = useState(false);
  const [isForgetPasswordFormOpen, setIsForgetPasswordFormOpen] =
    useState(false);
  const [code, setCode] = useState('');
  const [verificationLoading, setVerificationLoading] = useState(false);

  const onChangeFormMode = useCallback(() => {
    setError(null);

    if (authMode === 'sign-in') {
      setAuthMode('sign-up');
    }
    if (authMode === 'sign-up') {
      setAuthMode('sign-in');
    }
    if (authMode === 'forget') {
      setAuthMode('sign-in');
    }
  }, [authMode]);
  const onSignIn = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      setLoading(true);

      const data = {
        password,
        username: email,
      };

      api
        .post('/auth/sign-in', data)
        .then((res) => {
          localStorage.setItem('token', res.data.token);
          authCtx.signIn(res.data.company);
          navigate('/', { replace: true });
          setPassword('');
          setEmail('');
          setLoading(false);
        })
        .catch((err) => {
          setError(t(`errors.${errToString(err)}`));
          setLoading(false);
        });
    },
    [t, authCtx, email, password, navigate]
  );
  const onSignUp = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      setLoading(true);

      const data = {
        name: email?.toLowerCase(),
        username: email?.toLowerCase(),
        email: email?.toLowerCase(),
        phoneNumber,
        restaurantName: 'My settings',
        restaurantAddress: 'My settings address',
        countryCode: 'am',
        password,
        passwordConfirmation: confirmPassword,
        isTermsAndConditionsAccepted: termsIsChecked,
      };

      api
        .post('/auth/sign-up', data)
        .then(() => {
          setIsVerificationFormOpen(true);
          setPhoneNumber('');
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          setCode('');
          setTermsIsChecked(false);
          setLoading(false);
        })
        .catch((err) => {
          setError(t(`errors.${errToString(err)}`));
          setLoading(false);
        });
    },
    [t, termsIsChecked, email, password, confirmPassword, phoneNumber]
  );
  const onVerifyEmail = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      setVerificationLoading(true);

      api
        .post('/auth/sign-up/verify', { code })
        .then((res) => {
          localStorage.setItem('token', res.data.token);
          authCtx.signIn(res.data.company);
          setIsVerificationFormOpen(false);
          setVerificationLoading(false);
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          setTermsIsChecked(false);
          setCode('');
          return navigate('/', { replace: true });
        })
        .catch((err) => {
          setVerificationLoading(false);
          setError(errToString(err));
          console.log(errToString(err));
        });
    },
    [navigate, code, authCtx]
  );
  const onForgetPassword = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      setLoading(true);

      const data = {
        username: email,
      };

      api
        .post('/auth/forgot-password', data)
        .then(() => {
          setIsForgetPasswordFormOpen(true);
          setEmail('');
          setPassword('');
          setCode('');
          setLoading(false);
        })
        .catch((err) => {
          setError(t(`errors.${errToString(err)}`));
          setLoading(false);
        });
    },
    [t, email]
  );
  const onVerifyPassword = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      setVerificationLoading(true);

      api
        .post('/auth/forgot-password/verify', {
          password,
          passwordConfirmation: confirmPassword,
          code,
        })
        .then((res) => {
          setIsForgetPasswordFormOpen(false);
          setVerificationLoading(false);
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          setTermsIsChecked(false);
          setCode('');
          setAuthMode('sign-in');
        })
        .catch((err) => {
          setVerificationLoading(false);
          setError(errToString(err));
          console.log(errToString(err));
        });
    },
    [password, confirmPassword, code]
  );

  useEffect(() => {
    i18n.changeLanguage(lng);
  }, [lng]);

  return (
    <div className='auth'>
      <div className='container' id='container'>
        {!isVerificationFormOpen && !isForgetPasswordFormOpen && (
          <>
            <div className='form-container login-container'>
              <img src={LogoNew} alt='logo' />
              {authMode === 'sign-up' && (
                <SignUp
                  loading={loading}
                  email={email}
                  password={password}
                  confirmPassword={confirmPassword}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  setConfirmPassword={setConfirmPassword}
                  onSignUp={onSignUp}
                  setAuthMode={setAuthMode}
                  termsIsChecked={termsIsChecked}
                  setTermsIsChecked={setTermsIsChecked}
                  error={error}
                  setError={setError}
                  setPhoneNumber={setPhoneNumber}
                  phoneNumber={phoneNumber}
                />
              )}
              {authMode === 'sign-in' && (
                <SignIn
                  loading={loading}
                  email={email}
                  password={password}
                  setPassword={setPassword}
                  setEmail={setEmail}
                  setAuthMode={setAuthMode}
                  onSignIn={onSignIn}
                  error={error}
                  setError={setError}
                />
              )}
              {authMode === 'forget' && (
                <ForgetPassword
                  loading={loading}
                  email={email}
                  setEmail={setEmail}
                  setAuthMode={setAuthMode}
                  onForgetPassword={onForgetPassword}
                  error={error}
                  setError={setError}
                />
              )}
            </div>
            <div className='overlay-container'>
              <div className='overlay'>
                <div className='overlay-panel overlay-left'>
                  <h1>{t('auth.welcomeBack')}</h1>
                  <p>{t('auth.welcomeBackSubTitle')}</p>
                  <button className='ghost' id='signIn'>
                    {t('auth.signIn')}
                  </button>
                </div>
                <div className='overlay-panel overlay-right'>
                  <h1>{t('auth.helloFriend')}</h1>
                  <p>{t('auth.helloFriendSubTitle')}</p>
                  <button
                    onClick={onChangeFormMode}
                    className='ghost'
                    id='signUp'
                  >
                    {authMode === 'sign-in'
                      ? t('auth.signUp')
                      : t('auth.signIn')}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {isVerificationFormOpen && (
          <div className='container_modal'>
            <img src={LogoNew} alt='logo' />
            <h1>{t('general.emailVerification')}</h1>
            <span>{t('auth.checkEmailForVerification')}</span>
            <div className='form_error'>
              {error && (
                <div className='form_error_name'>
                  <PiWarningOctagonDuotone />
                  <p>{error}</p>
                </div>
              )}
            </div>
            <input
              autoComplete={'off'}
              value={code}
              type='text'
              placeholder={t('general.code')}
              onChange={(e) => {
                setCode(e.target.value);
                setError(null);
              }}
            />
            <button onClick={onVerifyEmail}>
              {!verificationLoading && t('general.verify')}
              {verificationLoading && <Loading />}
            </button>
          </div>
        )}
        {isForgetPasswordFormOpen && (
          <div className='container_modal'>
            <img src={LogoNew} alt='logo' />
            <h1>{t('general.passwordVerification')}</h1>
            <span>{t('auth.checkEmailForVerification')}</span>
            <div className='form_error'>
              {error && (
                <div className='form_error_name'>
                  <PiWarningOctagonDuotone />
                  <p>{error}</p>
                </div>
              )}
            </div>
            <input
              autoComplete={'off'}
              value={code}
              type='text'
              placeholder={t('general.code')}
              onChange={(e) => {
                setCode(e.target.value);
                setError(null);
              }}
            />
            <input
              autoComplete={'off'}
              value={password}
              type='password'
              placeholder={t('general.password')}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(null);
              }}
            />
            <input
              autoComplete={'off'}
              value={confirmPassword}
              type='password'
              placeholder={t('general.confirmPassword')}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setError(null);
              }}
            />
            <button onClick={onVerifyPassword}>
              {!verificationLoading && t('general.verify')}
              {verificationLoading && <Loading />}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(Auth);
