import { memo } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import Loading from '../loading';

function Warning({ onSubmit, onCancel, description, loading = false }) {
  const { t } = useTranslation();

  return createPortal(
    <div className='warning' onClick={(e) => e.stopPropagation()}>
      <div className='warning_in warning_in--active'>
        <h3>{t('components.warning.warning')}!</h3>
        <h2>{description}</h2>
        <div className='warning_in_actions'>
          {!loading && (
            <>
              <button
                className='warning_in_actions_btn warning_in_actions_btn--cancel'
                onClick={onCancel}
              >
                {t('general.cancel')}
              </button>
              <button
                className='warning_in_actions_btn warning_in_actions_btn--submit'
                onClick={onSubmit}
              >
                {t('general.yes')}
              </button>
            </>
          )}
          {loading && <Loading />}
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

Warning.propTypes = {
  description: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default memo(Warning);
