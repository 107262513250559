import './index.scss';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { GrFormAdd } from 'react-icons/gr';
import { IoIosRemove } from 'react-icons/io';
import PropTypes from 'prop-types';
import ShopContext from '../../../../../context/shop/shop-context';
import ShopCategoryProductDetails from './shop-category-product-details';
import { toPriceWithCurrency } from '../../../../../utils';
import PreferencesContext from '../../../../../context/preferences/preferences-context';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import cn from 'classnames';
import EditShopCategoryProductDetails from './edit-shop-category-product-details';
import { AiFillStar } from 'react-icons/ai';
import { FaRegHeart } from 'react-icons/fa6';
import { FaHeart } from 'react-icons/fa';

function ShopCategoryProduct({
  products,
  showAddBtn = true,
  clickable = true,
  showDetails = true,
  hasOverlay,
  setGrow = () => {},
  setShrink = () => {},
}) {
  const { t } = useTranslation();
  const {
    onAddItem,
    onRemoveItem,
    onRemoveItemAll,
    onRemoveItemSingleItem,
    products: basketProducts,
  } = useContext(ShopContext);
  const { list } = useContext(PreferencesContext);
  const [startX, setStartX] = useState(null);
  const [distance, setDistance] = useState(0);
  const [startXTotal, setStartXTotal] = useState(null);
  const [distanceTotal, setDistanceTotal] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeIndexTotal, setActiveIndexTotal] = useState(null);
  const [editableBasketItem, setEditableBasketItem] = useState(null);
  const [liked, setLiked] = useState([]);

  const [showProductDetailsPage, setShowProductDetailsPage] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const productsWithoutDuplicates = useMemo(
    () =>
      products.filter((product, index, self) => {
        return index === self.findIndex((p) => p.id === product.id);
      }),
    [products]
  );

  const handleTouchStart = useCallback((e, index) => {
    e.stopPropagation();
    setStartX(e.touches[0].clientX);
    setActiveIndex(index);
    setActiveIndexTotal(null);
  }, []);
  const handleTouchEnd = useCallback(() => {
    // setStartX(null);
    // setDistance(0);
  }, []);
  const handleTouchMove = useCallback(
    (e) => {
      e.stopPropagation();

      if (startX === null) {
        return;
      }

      const currentX = e.touches[0].clientX;
      const diffX = startX - currentX;

      if (diffX >= 120) {
        setDistance(120);
      }
      if (diffX <= 0) {
        setDistance(0);
      }
    },
    [startX]
  );
  const handleTouchStartTotal = useCallback(
    (e, index, product) => {
      if (basketProducts.find(({ id }) => id === product.id)) {
        setStartXTotal(e.touches[0].clientX);
        setActiveIndexTotal(index);
        setActiveIndex(null);
      }
    },
    [basketProducts]
  );
  const handleTouchEndTotal = useCallback(() => {
    // setStartX(null);
    // setDistance(0);
  }, []);
  const handleTouchMoveTotal = useCallback(
    (e) => {
      if (startXTotal === null) {
        return;
      }

      const currentX = e.touches[0].clientX;
      const diffX = startXTotal - currentX;

      if (diffX >= 120) {
        setDistanceTotal(120);
      }
      if (diffX <= 0) {
        setDistanceTotal(0);
      }
    },
    [startXTotal]
  );

  return (
    <>
      {productsWithoutDuplicates.map((product, index) => (
        <div
          key={`${product.id}:${product.name}:${index}:${index + product.id}`}
          className={cn('shop_category_product', {
            'shop_category_product--dark': false,
          })}
          style={{ position: 'relative' }}
          onTouchStart={(e) =>
            handleTouchStartTotal(e, `${product.id}:${index}`, product)
          }
          onTouchMove={(e) => handleTouchMoveTotal(e)}
          onTouchEnd={handleTouchEndTotal}
        >
          <div
            className='shop_category_product_basket_main'
            style={{
              transform:
                activeIndexTotal === `${product.id}:${index}`
                  ? `translateX(${-distanceTotal}px)`
                  : '',
              transition:
                activeIndexTotal === `${product.id}:${index}`
                  ? 'transform 0.3s ease-out'
                  : '',
            }}
          >
            <div className='shop_category_product_in'>
              <div
                className='shop_category_product_in_wrapper'
                onClick={() => {
                  if (!clickable) {
                    return;
                  }
                  setShowProductDetailsPage(true);
                  setSelectedProduct(product);
                }}
              >
                <div className='shop_category_product_img'>
                  <img src={product.src} alt={product.name} />
                </div>
                <div className='shop_category_product_in_footer'>
                  <div className='shop_category_product_wrapper'>
                    <p className='shop_category_product_name'>
                      {product.name}
                      <span
                        className='shop_category_product_name_icon'
                        onClick={(e) => {
                          e.stopPropagation();
                          setLiked((prevLiked) => {
                            if (prevLiked.includes(product.id)) {
                              return prevLiked.filter(
                                (likedId) => likedId !== product.id
                              );
                            } else {
                              return [...prevLiked, product.id];
                            }
                          });
                        }}
                      >
                        {liked.includes(product.id) ? (
                          <FaHeart />
                        ) : (
                          <FaRegHeart />
                        )}
                      </span>
                    </p>
                    <p className='shop_category_product_description'>
                      {!product.description ||
                      product.description === null ||
                      product.description === 'n/a'
                        ? '-'
                        : product.description}
                    </p>
                  </div>
                  <div className='shop_category_product_review'>
                    <span className='shop_category_product_review_icon'>
                      <AiFillStar />
                    </span>
                    <p className='shop_category_product_review_count'>5.0</p>
                  </div>
                  <div className='shop_category_product_actions'>
                    {product.price > 0 && (
                      <p className='shop_category_product_price'>
                        {toPriceWithCurrency(
                          Number(product.price),
                          list.restaurantInfo.currency
                        )}
                      </p>
                    )}
                    {showAddBtn && (
                      <div className='shop_category_product_actions_in'>
                        {basketProducts.find(({ id }) => id === product.id) && (
                          <span>
                            {basketProducts
                              .filter(({ id }) => id === product.id)
                              .reduce((acc, val) => acc + val.qty, 0)}
                            x
                          </span>
                        )}
                        <button
                          className='shop_category_product_actions_btn shop_category_product_actions_btn--add'
                          onClick={(e) => {
                            e.stopPropagation();

                            if (!product.options.length) {
                              setGrow(true);
                              setShrink(false);
                              return onAddItem({
                                ...product,
                                options: [],
                                note: product.note ? product.note : '',
                              });
                            }
                            setSelectedProduct(product);
                            return setShowProductDetailsPage(true);
                          }}
                        >
                          <GrFormAdd />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showDetails &&
              basketProducts
                .filter(({ id }) => id === product.id)
                .map((basketProduct, index) => (
                  <div
                    key={index}
                    className='shop_category_product_basket'
                    onTouchStart={(e) =>
                      handleTouchStart(e, `${basketProduct.id}:${index}`)
                    }
                    onTouchMove={(e) => handleTouchMove(e)}
                    onTouchEnd={handleTouchEnd}
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveIndexTotal(null);
                      setActiveIndex(null);
                      setShrink(true);
                      setGrow(false);
                      setEditableBasketItem(basketProduct);
                      const editableItemKey = `${basketProduct.id}:${basketProduct.options
                        .filter(({ isActive }) => isActive)
                        .map(({ name }) => name)
                        .join(
                          ','
                        )}:${basketProduct.note ? basketProduct.note : ''}`;
                      localStorage.setItem('editableItemKey', editableItemKey);
                      setSelectedProduct(
                        ...productsWithoutDuplicates.filter(
                          ({ id }) => id === basketProduct.id
                        )
                      );
                    }}
                  >
                    <div
                      className='shop_category_product_basket_main'
                      style={{
                        transform:
                          activeIndex === `${basketProduct.id}:${index}`
                            ? `translateX(${-distance}px)`
                            : '',
                        transition:
                          activeIndex === `${basketProduct.id}:${index}`
                            ? 'transform 0.3s ease-out'
                            : '',
                      }}
                    >
                      <div className='shop_category_product_basket_top'>
                        <p className='shop_category_product_basket_name'>
                          {product.name}
                        </p>
                        <p className='shop_category_product_basket_price'>
                          {toPriceWithCurrency(
                            Number(
                              product.price +
                                basketProduct.options.reduce(
                                  (acc, val) => acc + val.price,
                                  0
                                )
                            ),
                            list.restaurantInfo.currency
                          )}
                        </p>
                        <button
                          className='shop_category_product_basket_btn shop_category_product_basket_btn--remove'
                          onClick={(e) => {
                            e.stopPropagation();
                            setShrink(true);
                            setGrow(false);
                            onRemoveItem({
                              id: product.id,
                              options: basketProduct.options,
                              note: basketProduct.note,
                            });
                          }}
                        >
                          <IoIosRemove />
                        </button>
                        <span className='shop_category_product_basket_qty'>
                          {basketProduct.qty}x
                        </span>
                        <button
                          className='shop_category_product_basket_btn shop_category_product_basket_btn--add'
                          onClick={(e) => {
                            e.stopPropagation();
                            setGrow(true);
                            setShrink(false);
                            onAddItem({
                              ...product,
                              options: basketProduct.options,
                              note: basketProduct.note,
                            });
                          }}
                        >
                          <GrFormAdd />
                        </button>
                      </div>
                      <div className='shop_category_product_basket_bottom'>
                        {!!basketProduct.options.length && (
                          <div className='shop_category_product_basket_name_options'>
                            <p>
                              <span>{t('shop.extras')}:</span>
                              {basketProduct.options
                                .map(({ name }) => name)
                                .join(', ')
                                ?.toLowerCase()}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className='shop_category_product_basket_bottom'>
                        {basketProduct.note && (
                          <div className='shop_category_product_basket_name_options'>
                            <p>
                              <span>{t('general.notes')}:</span>
                              {basketProduct.note}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {activeIndex === `${basketProduct.id}:${index}` && (
                      <div
                        className='shop_category_product_basket_hidden'
                        style={{
                          right: distance > 0 ? `${-120 + distance}px` : '',
                          opacity: distance / 120,
                          transition:
                            'right 0.3s ease-out, opacity 0.3s ease-out',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveIndexTotal(null);
                          setActiveIndex(null);
                          setShrink(true);
                          setGrow(false);
                          onRemoveItemAll({
                            id: product.id,
                            options: basketProduct.options,
                            note: basketProduct.note,
                          });
                        }}
                      >
                        <BsTrash />
                      </div>
                    )}
                  </div>
                ))}
          </div>
          {activeIndexTotal === `${product.id}:${index}` && (
            <div
              className='shop_category_product_basket_hidden'
              style={{
                right: distanceTotal > 0 ? `${-120 + distanceTotal}px` : '',
                opacity: distanceTotal / 120,
                transition: 'right 0.3s ease-out, opacity 0.3s ease-out',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setActiveIndexTotal(null);
                setActiveIndex(null);
                onRemoveItemSingleItem(product);
              }}
            >
              <BsTrash />
            </div>
          )}
        </div>
      ))}
      {editableBasketItem && clickable && (
        <EditShopCategoryProductDetails
          basketItem={editableBasketItem}
          setBasketItem={setEditableBasketItem}
          product={selectedProduct}
          setProduct={setSelectedProduct}
          hasOverlay={!hasOverlay}
          isDarkMode={false}
        />
      )}
      {showProductDetailsPage && (
        <ShopCategoryProductDetails
          product={selectedProduct}
          setShowProductDetailsPage={setShowProductDetailsPage}
          hasOverlay={!hasOverlay}
          isDarkMode={false}
        />
      )}
    </>
  );
}

ShopCategoryProduct.propTypes = {
  products: PropTypes.array.isRequired,
  showAddBtn: PropTypes.bool,
  clickable: PropTypes.bool,
  showDetails: PropTypes.bool,
  hasOverlay: PropTypes.bool,
};

export default memo(ShopCategoryProduct);
