import React, { memo, useContext, useEffect, useState } from 'react';
import TerminalNav from './terminal-nav';
import cn from 'classnames';
import TerminalProduct from './terminal-product';
import TerminalContext from '../../../../context/terminal/terminal-context';
import Loading from '../../../../components/loading';
import TerminalBasket from './terminal-basket';
import TerminalCategoryProductDetails from './terminal-category-product-details';
import TerminalHeader from '../terminal-header ';
import { useTranslation } from 'react-i18next';

function TerminalWeb({
  selectedProduct,
  showProductDetailsPage,
  onCreateOrder,
  setSelectedProduct,
  setShowProductDetailsPage,
  categoriesLoading,
  categories,
  productsLoading,
  categoryProducts,
  setSelectedCategoryId,
  selectedCategoryId,
  pending,
  onClear,
  selectedTable,
  setSelectedTable,
  setIsTerminalOpen,
  onRefresh,
  checkForPos,
}) {
  const {
    onAddItem,
    onRemoveItem,
    products: basketProducts,
  } = useContext(TerminalContext);
  const { t } = useTranslation();
  const [showBasket, setShowBasket] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
        background: '#f8f9fa',
      }}
    >
      <TerminalHeader
        selectedTable={selectedTable}
        onClear={onClear}
        setSelectedTable={setSelectedTable}
        setIsTerminalOpen={setIsTerminalOpen}
        windowWidth={windowWidth}
        setShowBasket={setShowBasket}
        onRefresh={onRefresh}
      />
      <div className='terminal_in_body'>
        {!categoriesLoading && categories && (
          <>
            <TerminalNav
              categories={categories}
              setSelectedCategoryId={setSelectedCategoryId}
              selectedCategoryId={selectedCategoryId}
            />
            <ul className={cn('terminal_in_body_list')}>
              {categories && !productsLoading && !!categoryProducts?.length && (
                <TerminalProduct
                  categoryProducts={categoryProducts}
                  basketProducts={basketProducts}
                  onRemoveItem={onRemoveItem}
                  onAddItem={onAddItem}
                  setSelectedProduct={setSelectedProduct}
                  setShowProductDetailsPage={setShowProductDetailsPage}
                />
              )}
              {!productsLoading && !categoryProducts?.length && (
                <li className='terminal_in_body_item'>
                  {t('noData.products')}
                </li>
              )}
              {productsLoading && (
                <div className='terminal_in_body_list_loading'>
                  <Loading />
                </div>
              )}
            </ul>
            <TerminalBasket
              basketProducts={basketProducts}
              onCreateOrder={onCreateOrder}
              onAddItem={onAddItem}
              onRemoveItem={onRemoveItem}
              pending={pending}
              onClear={onClear}
              setSelectedTable={setSelectedTable}
              showBasket={showBasket}
              setShowBasket={setShowBasket}
              windowWidth={windowWidth}
            />
          </>
        )}
        {!categoriesLoading && !categories && (
          <li className='terminal_in_body_item'>{t('noData.categories')}</li>
        )}
        {(categoriesLoading || !checkForPos) && (
          <div className='terminal_in_body_loading'>
            <Loading />
          </div>
        )}
        {showProductDetailsPage && (
          <TerminalCategoryProductDetails
            product={selectedProduct}
            onAddItem={onAddItem}
            setShowProductDetailsPage={setShowProductDetailsPage}
          />
        )}
      </div>
    </div>
  );
}

export default memo(TerminalWeb);
