import './index.scss';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Wrapper from '../../../components/layout/wrapper';
import Table from '../../../components/table';
import NoData from '../../../components/no-data';
import Pagination from '../../../components/pagination';
import { LIMITS } from '../../../constants/general';
import api from '../../../utils/api';
import Loading from '../../../components/loading';
import DateTimePicker from '../../../components/calendar/date-time-picker-double-calendar';
import { getCurrentDateWithoutHours } from '../../../utils/date';
import { BsFillCartCheckFill } from 'react-icons/bs';
import moment from 'moment/moment';
import OrdersList from '../live-orders/orders-list';
import { errToString, toPriceWithCurrency } from '../../../utils';
import { useTranslation } from 'react-i18next';
import DropDown from '../../../components/drop-down';
import { AiFillStar } from 'react-icons/ai';
import AuthContext from '../../../context/auth/auth-context';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import Overlay from '../../../components/layout/overlay';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';

function Orders() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [invoices, setInvoices] = useState(null);
  const morePopupRef = useRef({});
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showOrders, setShowOrders] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);
  const [showReview, setShowReview] = useState(false);
  const [dateFields, setDateFields] = useState({
    startDate: getCurrentDateWithoutHours(),
    endDate: getCurrentDateWithoutHours(23, 59, 59),
  });
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branches, setBranches] = useState(null);

  const InvoicesTitles = useMemo(
    () => [
      {
        name: t('components.tables.invoice'),
      },
      {},
      {
        size: 50,
      },
    ],
    [t]
  );

  const onChangeLimit = useCallback(
    (option) => {
      if (option.value === limit.value) {
        return;
      }

      setLimit(option);
      setOffset(0);
      setLoading(true);
      setCurrentPage(0);
    },
    [limit]
  );

  useEffect(() => {
    const startDate = moment(dateFields.startDate).utc().format();
    let endDate = moment(dateFields.endDate).utc().format();
    if (!selectedBranch) {
      return;
    }

    api
      .get(
        `/invoices?offset=${offset}&limit=${limit.value}&status=closed&from=${startDate}&to=${endDate}&branchId=${selectedBranch.id}`
      )
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setLoading(false);
        setInvoices(res.data.results);
      })
      .catch((err) => {
        const audio = new Audio(sound);
        const info = `${err}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
        setLoading(false);
        if (errToString(err) === 'settings.primary.language.is.missing') {
          return setInvoices([]);
        }
      });
  }, [t, dateFields, limit, offset, selectedBranch]);
  useEffect(() => {
    api
      .get('/branches')
      .then(({ data }) => {
        setBranches(
          data.map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj.id,
          }))
        );
        setSelectedBranch(
          data.map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj.id,
          }))[0]
        );
      })
      .catch((err) => {
        console.log(errToString(err));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            <h2>{t('routes.orders')}</h2>
            <div className='page_in_header_filter'>
              {branches && (
                <>
                  <DropDown
                    placeholder={t('routes.branches')}
                    options={branches
                      .filter(({ deletedAt }) => !deletedAt)
                      .map((branch, index) => ({
                        option: branch,
                        el: <li key={index}>{branch.label}</li>,
                      }))}
                    value={selectedBranch}
                    onChange={(e) => setSelectedBranch(e)}
                    position='bottom'
                    loading={false}
                    showClose={false}
                  />
                  <div className='page_in_header_calendar'>
                    <DateTimePicker
                      placeholder='DD/MM/YYYY HH:mm - HH:mm'
                      enableTimePicker={true}
                      position='bottom'
                      theme='orders'
                      enableRangePicker={true}
                      value={[dateFields.startDate, dateFields.endDate]}
                      onChange={(e) => {
                        setDateFields(e);
                        setOffset(0);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {!loading && (
            <div className='page_in_content'>
              {!!invoices?.length && (
                <>
                  <Table
                    route={false}
                    loading={false}
                    titles={InvoicesTitles}
                    rows={
                      invoices?.map((invoice, index) => ({
                        invoice: (
                          <div key={index} className='page_in_content_table'>
                            <p className='page_in_content_table_invoice'>
                              #{invoice.internalId}
                            </p>
                            <p className='page_in_content_table_invoice'>
                              {t('components.tables.area')}:{' '}
                              {invoice.table?.area?.name}
                            </p>
                            <p className='page_in_content_table_invoice'>
                              {t('components.tables.table')}:{' '}
                              {invoice.table?.name}
                            </p>
                            <p className='page_in_content_table_price'>
                              {t('general.cash')}
                            </p>
                            <p className='page_in_content_table_price'>
                              {toPriceWithCurrency(
                                Number(invoice.totalAmount),
                                user.restaurant.restaurantInfo.currency
                              )}
                            </p>
                            <p className='page_in_content_table_date'>
                              {moment(invoice.createdAt).format(
                                `DD MMM YYYY, HH:mm`
                              )}
                            </p>
                            <span
                              className={`page_in_content_table_status page_in_content_table_status--${invoice.status}`}
                            >
                              {t(`general.${invoice.status}`)}
                            </span>
                          </div>
                        ),
                        rating:
                          invoice.rating === null ? (
                            <p className='page_in_content_table_review'></p>
                          ) : (
                            <div className='page_in_content_table_review'>
                              <p className='page_in_content_table_review_rating'>
                                <AiFillStar fill={'#f4c52c'} />
                                {invoice.rating}
                              </p>
                              {invoice.review && (
                                <>
                                  <span
                                    ref={(el) =>
                                      (morePopupRef.current[invoice.id] = el)
                                    }
                                    className='page_in_content_table_review_note'
                                    onClick={() => {
                                      if (showReview === invoice.id) {
                                        return setShowReview(null);
                                      }
                                      return setShowReview(invoice.id);
                                    }}
                                  >
                                    {t('components.tables.review')}
                                  </span>
                                  {showReview === invoice.id &&
                                    morePopupRef &&
                                    createPortal(
                                      <>
                                        <div
                                          className={cn(
                                            'page_in_content_actions_popup page_in_content_actions_popup--review',
                                            {
                                              'page_in_content_actions_popup--show':
                                                showReview,
                                            }
                                          )}
                                          style={{
                                            top:
                                              morePopupRef.current[
                                                invoice.id
                                              ].getBoundingClientRect().top +
                                              35,
                                            right: 30,
                                          }}
                                        >
                                          <p>{invoice.review}</p>
                                        </div>
                                        <Overlay
                                          isTransparent={true}
                                          onClick={() => setShowReview(null)}
                                        />
                                      </>,
                                      document.getElementById('modal')
                                    )}
                                </>
                              )}
                            </div>
                          ),
                        actions: (
                          <div className='page_in_content_actions'>
                            <button
                              onClick={() => {
                                setShowOrders(true);
                                return setSelectedInvoice(invoice);
                              }}
                              style={
                                windowWidth >= 600 ? { marginRight: 10 } : {}
                              }
                              className='page_in_content_actions_btn page_in_content_actions_btn--orders'
                            >
                              <BsFillCartCheckFill />
                              {t('general.details')}
                            </button>
                          </div>
                        ),
                      })) || []
                    }
                  />
                  {!!pagesCount && (
                    <div className='page_in_pagination'>
                      <Pagination
                        pagesCount={pagesCount}
                        setOffset={setOffset}
                        limit={limit}
                        limitPlaceholder={limit.label}
                        limits={LIMITS}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        onChange={onChangeLimit}
                      />
                    </div>
                  )}
                </>
              )}
              {!invoices?.length && (
                <NoData
                  title={`${t('noData.ordersRange')}: ${moment(dateFields.startDate).format(`DD/MM/YYYY`)} - ${moment(dateFields.endDate).format(`DD/MM/YYYY`)}`}
                />
              )}
            </div>
          )}
          {loading && (
            <div className='loading_fullscreen'>
              <Loading />
            </div>
          )}
        </div>
      </div>
      {showOrders && selectedInvoice && (
        <OrdersList
          type={'orders'}
          setShowOrders={setShowOrders}
          invoice={selectedInvoice}
        />
      )}
    </Wrapper>
  );
}

export default memo(Orders);
