import './index.scss';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Wrapper from '../../../components/layout/wrapper';
import Table from '../../../components/table';
import Loading from '../../../components/loading';
import { BsFillCartCheckFill, BsThreeDots } from 'react-icons/bs';
import OrdersList from './orders-list';
import api from '../../../utils/api';
import { GrMoney } from 'react-icons/gr';
import Pagination from '../../../components/pagination';
import { LIMITS } from '../../../constants/general';
import Warning from '../../../components/warning';
import NoData from '../../../components/no-data';
import { errToString, toPriceWithCurrency } from '../../../utils';
import { IoIosCloseCircle, IoMdRefreshCircle } from 'react-icons/io';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaPeopleArrows } from 'react-icons/fa';
import Overlay from '../../../components/layout/overlay';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import Terminal from '../terminal';
import DropDown from '../../../components/drop-down';
import AddLanguage from '../menu/add-language';
import AuthContext from '../../../context/auth/auth-context';
import moment from 'moment';

function LiveOrders() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { invoices } = useOutletContext();
  const morePopupRef = useRef({});
  const navigate = useNavigate();

  const [showMore, setShowMore] = useState(null);
  const [lngId, setLngId] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [pagesCount, setPagesCount] = useState(null);
  const [tables, setTables] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [showOrders, setShowOrders] = useState(false);
  const [loading, setLoading] = useState(true);
  const [warningForPayWithCash, setWarningForPayWithCash] = useState(false);
  const [warningForPayWithCard, setWarningForPayWithCard] = useState(false);
  const [warningForReset, setWarningForReset] = useState(false);
  const [openChangeTableForm, setOpenChangeTableForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [isTerminalOpen, setIsTerminalOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branches, setBranches] = useState(null);
  const [mount, setMount] = useState('loading');
  const [showCreateLng, setShowCreateLng] = useState(false);
  const [warningLoading, setWarningLoading] = useState(false);

  const InvoicesTitles = useMemo(
    () => [
      {
        name: t('components.tables.invoice'),
      },
      {
        size: 150,
      },
    ],
    [t]
  );
  const TablesTitles = useMemo(
    () => [
      {
        name: `${t('components.tables.area')}`,
        size: 150,
      },
      {
        name: `${t('components.tables.table')}`,
        size: 300,
      },
      {
        name: t('components.tables.status'),
      },
    ],
    [t]
  );

  const onChangeLimit = useCallback(
    (option) => {
      if (option.value === limit.value) {
        return;
      }

      setLimit(option);
      setOffset(0);
      setLoading(true);
      setCurrentPage(0);
    },
    [limit]
  );
  const onPayWithCash = useCallback(() => {
    setWarningLoading(true);

    api
      .delete(`/tables/${selectedInvoice.table.id}/close?status=paid`)
      .then(() => {
        setWarningForPayWithCash(false);

        const audio = new Audio(sound);
        const info = `${selectedInvoice.internalId} ${t('notifications.payInvoice')}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();

        setWarningLoading(false);
        return invoices.setInvoices(
          invoices.invoices
            .map((_invoice) => {
              if (_invoice.id === selectedInvoice.id) {
                return {
                  ...selectedInvoice,
                  status: 'paid',
                };
              }

              return _invoice;
            })
            .filter((_invoice) => _invoice.id !== selectedInvoice.id)
        );
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
        setWarningLoading(false);
      });
  }, [t, invoices, selectedInvoice]);
  const onPayWithCard = useCallback(() => {
    setWarningLoading(true);
    api
      .delete(
        `/tables/${selectedInvoice.table.id}/close?status=paid&payed_card=1`
      )
      .then(() => {
        setWarningForPayWithCard(false);
        setWarningLoading(false);

        const audio = new Audio(sound);
        const info = `${selectedInvoice.internalId} ${t('notifications.payInvoice')}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();

        return invoices.setInvoices(
          invoices.invoices
            .map((_invoice) => {
              if (_invoice.id === selectedInvoice.id) {
                return {
                  ...selectedInvoice,
                  status: 'paid',
                };
              }

              return _invoice;
            })
            .filter((_invoice) => _invoice.id !== selectedInvoice.id)
        );
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        setWarningLoading(false);
      });
  }, [t, invoices, selectedInvoice]);
  const onReset = useCallback(() => {
    setWarningLoading(true);
    api
      .delete(`/tables/${selectedInvoice.table.id}/close?status=canceled`)
      .then(() => {
        setWarningForReset(false);

        const audio = new Audio(sound);
        const info = `${selectedInvoice.internalId} ${t('notifications.cancelInvoice')}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
        setWarningLoading(false);
        return invoices.setInvoices(
          invoices.invoices
            .map((_invoice) => {
              if (_invoice.id === selectedInvoice.id) {
                return {
                  ...selectedInvoice,
                  status: 'paid',
                };
              }

              return _invoice;
            })
            .filter((_invoice) => _invoice.id !== selectedInvoice.id)
        );
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        setWarningLoading(false);
      });
  }, [t, selectedInvoice, invoices]);
  const onRefresh = useCallback(() => {
    setLoading(true);

    if (!selectedBranch) {
      return;
    }

    api
      .get(
        `/invoices?offset=${offset}&limit=${limit.value}&status=unpaid&branchId=${selectedBranch.id}`
      )
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setLoading(false);
        return invoices.setInvoices(res.data.results);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        setLoading(false);
        if (errToString(err) === 'settings.primary.language.is.missing') {
          return invoices.setInvoices([]);
        }
      });
  }, [offset, limit.value, invoices, t, selectedBranch]);
  const onChangeTable = useCallback(
    (table) => {
      if (!selectedInvoice) {
        return;
      }

      const data = {
        tableId: table.id,
      };

      api
        .put(`invoices/${selectedInvoice.id}`, data)
        .then(() => {
          api
            .delete(`/tables/${selectedInvoice.table.id}/close?status=canceled`)
            .then(() => {
              const audio = new Audio(sound);
              const info = `${selectedInvoice.internalId} ${t('notifications.changeTable')}`;
              toast.info(info, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              });
              audio.play();
              setOpenChangeTableForm(false);
              setSelectedTable(null);
              setSelectedInvoice(null);
            })
            .catch((err) => console.log(t(`errors.${errToString(err)}`)));
        })
        .catch((err) => {
          console.log(errToString(err));
        })
        .finally(() => {
          setSelectedTable(null);
          setSelectedInvoice(null);
          setOpenChangeTableForm(false);
          onRefresh();
        });
    },
    [t, onRefresh, selectedInvoice]
  );

  useEffect(() => {
    setLoading(true);

    if (!selectedBranch) {
      return;
    }

    api
      .get(
        `/invoices?offset=${offset}&limit=${limit.value}&status=unpaid&branchId=${selectedBranch.id}`
      )
      .then((res) => {
        setLoading(false);
        setPagesCount(res.data.pagesCount);
        return invoices.setInvoices(res.data.results);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        setLoading(false);
        setShowCreateLng(true);
        if (errToString(err) === 'settings.primary.language.is.missing') {
          invoices.setInvoices([]);
        }
      });

    // eslint-disable-next-line
  }, [t, limit, offset, invoices.setInvoices, selectedBranch]);
  useEffect(() => {
    api
      .get('restaurant/languages')
      .then((res) => {
        if (!!res.data.length) {
          return setLngId(res.data.filter(({ isPrimary }) => isPrimary)[0].id);
        }
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        setMount('close');
      });
  }, [t]);
  useEffect(() => {
    if (!selectedBranch) {
      return;
    }

    api
      .get(`/tables?offset=0&limit=1000&branchId=${selectedBranch.id}`)
      .then((res) => {
        return setTables(res.data.results);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        setMount('close');
      });
  }, [t, selectedBranch]);
  useEffect(() => {
    api
      .get('/branches')
      .then(({ data }) => {
        if (!!data.length) {
          setBranches(
            data.map((obj) => ({
              ...obj,
              label: obj.name,
              value: obj.id,
            }))
          );
          setSelectedBranch(
            data.map((obj) => ({
              ...obj,
              label: obj.name,
              value: obj.id,
            }))[0]
          );
        } else {
          setMount('no-tablets');
        }
      })
      .catch((err) => {
        console.log(errToString(err));
        setMount('close');
      });
  }, []);
  useEffect(() => {
    if (loading) {
      return;
    }

    if (branches && lngId && tables && invoices) {
      if (!!branches.length && selectedBranch) {
        if (selectedBranch.tablets.length > 0) {
          return setMount('show');
        } else {
          return setMount('no-tablets');
        }
      } else {
        return setMount('close');
      }
    }
  }, [selectedBranch, loading, branches, invoices, lngId, tables]);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          {mount === 'show' && (
            <>
              <div className='page_in_header'>
                {!isTerminalOpen && !openChangeTableForm && (
                  <h2>
                    {t('routes.live_orders')}
                    <IoMdRefreshCircle onClick={onRefresh} />
                  </h2>
                )}
                {isTerminalOpen && !openChangeTableForm && (
                  <h2>{t('routes.tables')}</h2>
                )}
                {openChangeTableForm && selectedTable && tables && (
                  <h2>
                    {t('general.transfer')} - {selectedTable.name}
                  </h2>
                )}
                <div className='page_in_header_filter'>
                  {branches && (
                    <DropDown
                      placeholder={t('routes.branches')}
                      options={branches
                        .filter(({ deletedAt }) => !deletedAt)
                        .map((branch, index) => ({
                          option: branch,
                          el: <li key={index}>{branch.label}</li>,
                        }))}
                      value={selectedBranch}
                      onChange={(e) => setSelectedBranch(e)}
                      position='bottom'
                      loading={false}
                      showClose={false}
                    />
                  )}
                </div>
                {lngId && !openChangeTableForm && (
                  <div
                    className='live_orders_switcher'
                    onClick={() => {
                      setIsTerminalOpen(!isTerminalOpen);
                      return onRefresh();
                    }}
                  >
                    {isTerminalOpen && (
                      <>
                        <div className='live_orders_switcher_btn live_orders_switcher_btn--active'>
                          {t('general.create')}
                        </div>
                        <div className='live_orders_switcher_btn'>
                          {t('routes.live_orders')}
                        </div>
                      </>
                    )}
                    {!isTerminalOpen && (
                      <>
                        <div className='live_orders_switcher_btn'>
                          {t('general.create')}
                        </div>
                        <div className='live_orders_switcher_btn live_orders_switcher_btn--active'>
                          {t('routes.live_orders')}
                        </div>
                      </>
                    )}
                  </div>
                )}
                {openChangeTableForm && selectedTable && tables && (
                  <button
                    className='page_in_header_btn'
                    style={{ marginLeft: 0 }}
                    onClick={() => {
                      setOpenChangeTableForm(false);
                      setSelectedTable(null);
                      setSelectedInvoice(null);
                    }}
                  >
                    {t('general.back')}
                  </button>
                )}
              </div>
              <div className='page_in_content'>
                {!loading &&
                  !isTerminalOpen &&
                  !openChangeTableForm &&
                  !!invoices.invoices?.length && (
                    <>
                      <Table
                        route={false}
                        titles={InvoicesTitles}
                        loading={false}
                        rows={
                          invoices.invoices.map((invoice, index) => ({
                            invoice: (
                              <div
                                key={index}
                                className='page_in_content_table'
                              >
                                <p className='page_in_content_table_invoice'>
                                  #{invoice.internalId}
                                </p>
                                <p className='page_in_content_table_invoice'>
                                  {t('components.tables.area')}:{' '}
                                  {invoice.table?.area?.name}
                                </p>
                                <p className='page_in_content_table_invoice'>
                                  {t('components.tables.table')}:{' '}
                                  {invoice.table?.name}
                                </p>
                                <p className='page_in_content_table_price'>
                                  {toPriceWithCurrency(
                                    Number(invoice.totalAmount),
                                    user.restaurant.restaurantInfo.currency
                                  )}
                                </p>
                                <p className='page_in_content_table_date page_in_content_table_date--left'>
                                  {moment(invoice.createdAt).format(
                                    `DD MMM YYYY, HH:mm`
                                  )}
                                </p>
                                <span className='page_in_content_table_status'>
                                  {t('general.pending')}
                                </span>
                              </div>
                            ),
                            actions: (
                              <div className='page_in_content_actions'>
                                <button
                                  className='page_in_content_actions_btn page_in_content_actions_btn--orders'
                                  onClick={() => {
                                    setShowOrders(true);
                                    return setSelectedInvoice(invoice);
                                  }}
                                >
                                  <BsFillCartCheckFill />
                                  {t('general.details')}
                                </button>
                                <div
                                  ref={(el) =>
                                    (morePopupRef.current[invoice.id] = el)
                                  }
                                  className='page_in_content_actions_icon'
                                  onClick={() => {
                                    if (showMore === invoice.id) {
                                      return setShowMore(null);
                                    }
                                    return setShowMore(invoice.id);
                                  }}
                                >
                                  <BsThreeDots />
                                </div>
                                {showMore === invoice.id &&
                                  morePopupRef &&
                                  createPortal(
                                    <>
                                      <div
                                        className={cn(
                                          'page_in_content_actions_popup',
                                          {
                                            'page_in_content_actions_popup--show':
                                              showMore,
                                          }
                                        )}
                                        style={{
                                          top:
                                            morePopupRef.current[
                                              invoice.id
                                            ].getBoundingClientRect().top + 45,
                                          right: 40,
                                        }}
                                      >
                                        <button
                                          className='page_in_content_actions_more_btn'
                                          onClick={() => {
                                            setShowMore(false);
                                            setWarningForPayWithCash(true);
                                            return setSelectedInvoice(invoice);
                                          }}
                                        >
                                          <GrMoney />
                                          {t('general.payWithCash')}
                                        </button>
                                        <button
                                          className='page_in_content_actions_more_btn'
                                          onClick={() => {
                                            setShowMore(false);
                                            setWarningForPayWithCard(true);
                                            return setSelectedInvoice(invoice);
                                          }}
                                        >
                                          <GrMoney />
                                          {t('general.payWithCard')}
                                        </button>
                                        <button
                                          className='page_in_content_actions_more_btn'
                                          onClick={() => {
                                            setShowMore(false);
                                            setWarningForReset(true);
                                            return setSelectedInvoice(invoice);
                                          }}
                                        >
                                          <IoIosCloseCircle />
                                          {t('general.reset')}
                                        </button>
                                        {tables && (
                                          <button
                                            className='page_in_content_actions_more_btn'
                                            onClick={() => {
                                              setShowMore(false);
                                              setOpenChangeTableForm(true);
                                              setSelectedTable(invoice.table);
                                              return setSelectedInvoice(
                                                invoice
                                              );
                                            }}
                                          >
                                            <FaPeopleArrows />
                                            {t('general.transfer')}
                                          </button>
                                        )}
                                      </div>
                                      <Overlay
                                        isTransparent={true}
                                        onClick={() => setShowMore(null)}
                                      />
                                    </>,
                                    document.getElementById('modal')
                                  )}
                              </div>
                            ),
                          })) || []
                        }
                      />
                      {!!pagesCount && (
                        <div className='page_in_pagination'>
                          <Pagination
                            pagesCount={pagesCount}
                            setOffset={setOffset}
                            limit={limit}
                            limitPlaceholder={limit.label}
                            limits={LIMITS}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            onChange={onChangeLimit}
                          />
                        </div>
                      )}
                    </>
                  )}
                {!loading &&
                  !isTerminalOpen &&
                  !openChangeTableForm &&
                  !invoices.invoices?.length && (
                    <NoData title={t('noData.orders')} />
                  )}
                {!loading &&
                  isTerminalOpen &&
                  !openChangeTableForm &&
                  branches && (
                    <Terminal
                      branch={selectedBranch}
                      lngId={lngId}
                      setIsTerminalOpen={setIsTerminalOpen}
                      onRefresh={onRefresh}
                      selectedBranch={selectedBranch}
                    />
                  )}
                {!loading && openChangeTableForm && selectedInvoice && (
                  <>
                    <div className='terminal_in_body'>
                      <div className='terminal_in_body_tables'>
                        <div className='terminal_in_body_tables_in'>
                          {!!tables
                            .filter(({ deletedAt }) => !deletedAt)
                            .filter(({ id }) => id !== selectedTable.id)
                            .length && (
                            <Table
                              route={false}
                              titles={TablesTitles}
                              onClick={onChangeTable}
                              rows={
                                tables
                                  .filter(({ id }) => id !== selectedTable.id)
                                  .map((table) => ({
                                    id: table.id,
                                    row: table,
                                    table: (
                                      <button className='page_in_content_id'>
                                        {table?.name}
                                      </button>
                                    ),
                                    area: (
                                      <button className='page_in_content_id'>
                                        {table?.area?.name}
                                      </button>
                                    ),
                                    status: (
                                      <button
                                        className={`page_in_content_status page_in_content_status--${table.isActive ? 'busy' : 'free'}`}
                                      >
                                        {table.isActive
                                          ? t('general.busy')
                                          : t('general.free')}
                                      </button>
                                    ),
                                  })) || []
                              }
                              hiddenFields={['id', 'row']}
                              loading={false}
                            />
                          )}
                        </div>
                        {!tables
                          .filter(({ deletedAt }) => !deletedAt)
                          .filter(({ id }) => id !== selectedTable.id)
                          .length && <NoData title={t('noData.tables')} />}
                      </div>
                    </div>
                  </>
                )}
                {loading && <Loading />}
              </div>
            </>
          )}
          {mount === 'loading' && !showCreateLng && (
            <>
              <div className='page_in_header'>
                {!isTerminalOpen && !openChangeTableForm && (
                  <h2>
                    {t('routes.live_orders')}
                    <IoMdRefreshCircle onClick={onRefresh} />
                  </h2>
                )}
                {isTerminalOpen && !openChangeTableForm && (
                  <h2>{t('routes.tables')}</h2>
                )}
                {openChangeTableForm && selectedTable && tables && (
                  <h2>
                    {t('general.transfer')} - {selectedTable.name}
                  </h2>
                )}
                <div className='page_in_header_filter'>
                  {branches && (
                    <DropDown
                      placeholder={t('routes.branches')}
                      options={branches
                        .filter(({ deletedAt }) => !deletedAt)
                        .map((branch, index) => ({
                          option: branch,
                          el: <li key={index}>{branch.label}</li>,
                        }))}
                      value={selectedBranch}
                      onChange={(e) => setSelectedBranch(e)}
                      position='bottom'
                      loading={false}
                      showClose={false}
                    />
                  )}
                </div>

                {lngId &&
                  !openChangeTableForm &&
                  mount !== 'no-tablets' &&
                  mount !== 'loading' && (
                    <div
                      className='live_orders_switcher'
                      onClick={() => setIsTerminalOpen(!isTerminalOpen)}
                    >
                      {isTerminalOpen && (
                        <>
                          <div className='live_orders_switcher_btn live_orders_switcher_btn--active'>
                            {t('general.create')}
                          </div>
                          <div className='live_orders_switcher_btn'>
                            {t('routes.live_orders')}
                          </div>
                        </>
                      )}
                      {!isTerminalOpen && (
                        <>
                          <div className='live_orders_switcher_btn'>
                            {t('general.create')}
                          </div>
                          <div className='live_orders_switcher_btn live_orders_switcher_btn--active'>
                            {t('routes.live_orders')}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                {openChangeTableForm && selectedTable && tables && (
                  <button
                    className='page_in_header_btn'
                    style={{ marginLeft: 0 }}
                    onClick={() => {
                      setOpenChangeTableForm(false);
                      setSelectedTable(null);
                      setSelectedInvoice(null);
                    }}
                  >
                    {t('general.back')}
                  </button>
                )}
              </div>
              <div className='loading_fullscreen'>
                <Loading />
              </div>
            </>
          )}
          {mount === 'loading' && showCreateLng && (
            <div className='page_in_content'>
              <AddLanguage
                type={'live-orders'}
                primary={false}
                setShowCreateLng={setMount}
                setLngId={setLngId}
                setCreatedLanguages={() => {}}
              />
            </div>
          )}
          {mount === 'no-tablets' && (
            <>
              <div className='page_in_header'>
                <h2>{t('routes.live_orders')}</h2>
                <div className='page_in_header_filter' style={{ width: 270 }}>
                  {branches && (
                    <DropDown
                      placeholder={t('routes.branches')}
                      options={branches
                        .filter(({ deletedAt }) => !deletedAt)
                        .map((branch, index) => ({
                          option: branch,
                          el: <li key={index}>{branch.label}</li>,
                        }))}
                      value={selectedBranch}
                      onChange={(e) => setSelectedBranch(e)}
                      position='bottom'
                      loading={false}
                      showClose={false}
                    />
                  )}
                </div>
              </div>
              <div className='page_in_content'>
                <div className='menu_in_content_importing'>
                  <div className='menu_in_content_importing_title'>
                    <h2>{t('general.doNotHaveTablet')}</h2>
                    <h3>{t('general.pleaseCreateTablet')}</h3>
                  </div>
                  <div className='tables_warning'>
                    <button
                      className='tables_warning_btn'
                      onClick={() => navigate('/branches')}
                    >
                      {t('general.addTablet')}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {mount === 'close' && (
            <>
              <div className='page_in_header'>
                <h2>{t('routes.live_orders')}</h2>
              </div>
              <div className='page_in_content'>
                <div className='menu_in_content_importing'>
                  <div className='menu_in_content_importing_title'>
                    <h2>{t('errors.somethingWentWrong')}</h2>
                    <h3>{t('errors.refresh')}</h3>
                  </div>
                  <div className='tables_warning'>
                    <button
                      className='tables_warning_btn'
                      onClick={() => window.location.reload()}
                    >
                      {t('general.refresh')}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showOrders && selectedInvoice && (
        <OrdersList
          setShowOrders={setShowOrders}
          invoice={selectedInvoice}
          invoices={invoices.invoices}
          setInvoices={invoices.setInvoices}
          type={'live'}
          onRefresh={onRefresh}
          setSelectedInvoice={setSelectedInvoice}
        />
      )}
      {warningForPayWithCash && (
        <Warning
          description={`${t('components.warning.mainTxt')} ${t('components.warning.pay')} invoice #${selectedInvoice.internalId}`}
          onCancel={() => {
            setWarningForPayWithCash(false);
            setShowMore(false);
          }}
          onSubmit={onPayWithCash}
          loading={warningLoading}
        />
      )}
      {warningForPayWithCard && (
        <Warning
          description={`${t('components.warning.mainTxt')} ${t('components.warning.pay')} invoice #${selectedInvoice.internalId}`}
          onCancel={() => {
            setWarningForPayWithCard(false);
            setShowMore(false);
          }}
          loading={warningLoading}
          onSubmit={onPayWithCard}
        />
      )}
      {warningForReset && (
        <Warning
          description={`${t('components.warning.mainTxt')} ${t('components.warning.reset')} invoice #${selectedInvoice.internalId}`}
          onCancel={() => {
            setWarningForReset(false);
            setShowMore(false);
          }}
          onSubmit={onReset}
          loading={warningLoading}
        />
      )}
    </Wrapper>
  );
}

export default memo(LiveOrders);
